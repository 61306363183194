$black: #000000;
$white: #ffffff;
$background-gray: #f7f8fa;
$primary-color: #ffb22b;
$input-background-gray: #f2f4f9;
$secondary-color: #4d4edb;
$danger-color: #ff6362;
$button-gray-color: #ebebff;
$checkbox-background-green: #0fdf90;
$modal-background-color: #f8f8f8;
$label-color: rgba(7, 7, 7, 0.3);
$checkbox-background-grey: #d9d9d9;
$disable-button-background-color: #ebebff;
$verify-notification-background-primary: #ff9e00;
$verify-notification-background-secondary: #ff6000;
$box-shadow: #ccc;
$activity-border: #a9a9a9;
$card-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.07);
$img-background: rgba(242, 244, 249, 0.5);
$activity-border: #a9a9a9;
$text-secondary-color: rgba(0, 0, 0, 0.3);
$radio-button-color: rgba(0, 0, 0, 0.65);
$radio-button-checked-background-color: #ececec;
$border-color: rgba(0, 0, 0, 0.1);
$background-no-location: #25b8f4;
$icon-color-no-location: #0584e9;
$addbar-shadow-box: 0 2px 26px 0 rgba(0, 0, 0, 0.16);
$text-color-blue: #6c63ff;
$text-color-grey: #f6f6f6;
$open-map-btn-color: #ffa303;
$input-primary-light-hover: #fffcf0;
$clipboard-text-color: #8c8c8c;
$split-blue-color: #00008b;
$light-blue-color: #5fdaf6;
$thick-black: #151515;
$light-orange: #ffbd54;
$thick-orange: #f56a2f;
$medium-green: #00c151;
$light-green: #0ede90;
$thick-green: #4ec80b;
$light-black: #4f4f4f;
$thick-grey: #777777;
$mild-green: #34c759;
$mild-grey: #d8d8d8;
$danger-red: #ff6363;
$mild-orange: #ff9d00;
$light-blue: #ecf7ff;
$light-grey: #c3c8d4;
