@import "variables";

.btn-md {
  width: 120px;
}

.btn-lg {
  width: 210px;
}

.btn-secondary {
  color: $white;
  height: 34px;
  background-color: $secondary-color;
  border-color: $secondary-color;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    background-color: $secondary-color;
    border-color: $secondary-color;
    filter: brightness(130%);
  }
  &:focus {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  &[disabled], &[disabled]:hover {
    background-color: $button-gray-color;
    border: none;
    color: $white;
    filter: none;
  }
}

.btn-grey {
  color: $black;
  height: 34px;
  background-color: $button-gray-color;
  border-color: $button-gray-color;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  &:hover {
    background-color: $button-gray-color;
    border-color: $button-gray-color;
    color: $black;
    filter: brightness(90%);
  }
  &:focus {
    background-color: $button-gray-color;
    border-color: $button-gray-color;
    color: $black;
  }
}

.btn-danger {
  color: $white;
  height: 34px;
  background-color: $danger-color;
  border-color: $danger-color;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  &:hover {
    background-color: $danger-color;
    border-color: $danger-color;
    color: $white;
    filter: brightness(90%);
  }
  &:focus {
    background-color: $danger-color;
    border-color: $danger-color;
    color: $white;
  }
}

.btn-danger-outline {
  color: $danger-color;;
  height: 34px;
  background-color: $button-gray-color;
  border-color: $button-gray-color;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  &:hover {
    border-color: $button-gray-color;
    background-color: $button-gray-color;
    color: $danger-color;
  }
  &:focus {
    border-color: $button-gray-color;
    background-color: $button-gray-color;
    color: $danger-color;
  }
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  background: rgba(0, 0, 0, 0.3);
  border-color: transparent;
  color: $white;
}

.btn-light-primary {
  color: $primary-color;
  height: 34px;
  background-color: $input-background-gray;
  border-color: $input-background-gray;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  &:hover {
    background-color: $button-gray-color;
    border-color: $input-background-gray;
    color: $primary-color;
    filter: brightness(90%);
  }
  &:focus {
    background-color: $button-gray-color;
    border-color: $input-background-gray;
    color: $primary-color;
  }
}

.btn-blue {
  color: $white;
  height: 34px;
  background-color: $background-no-location;
  border-color: $button-gray-color;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  &:hover {
    background-color: $background-no-location;
    border-color: $button-gray-color;
    color: $white;
    filter: brightness(90%);
  }
  &:focus {
    background-color: $background-no-location;
    border-color: $button-gray-color;
    color: $white;
  }
}