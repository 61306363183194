@import "../../styles/variables";

.on-boarding-modal {
  .ant-modal-close {
    top: 52px;
    right: 46px;
    width: 24px !important;
    height: 24px !important;
    .ant-modal-close-x {
      width: 24px !important;
      height: 24px !important;
      line-height: normal;
    }
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    .ant-modal-body {
      padding: 47px 50px 50px 50px !important;
      position: relative;
      .header {
        font-size: 22px;
        font-weight: bold;
        color: $black;
        line-height: normal;
        &.alert-height {
          margin-bottom: 90px;
        }
      }
      .alert-success-message {
        display: flex;
        position: absolute;
        top: 86px;
        transform: scaleY(1);
        transform-origin: top;
        transition: transform 0.3s ease;
        width: calc(100% - 100px);
        &.alert-off {
          transform: scaleY(0);
        }
        &.display-off {
          display: none;
        }
        .icon {
          background-color: $medium-green;
          padding: 15px 7px;
          align-self: stretch;
          display: flex;
          align-items: center;
        }
        .message {
          background-color: $light-green ;
          padding: 7px 14px 8px 10px;
          font-size: 14px;
          font-weight: 500;
          color: $black;
          line-height: normal;
        }
      }
      .progress-bar {
        display: flex;
        margin: 40px 0 30px 0;
        justify-content: center;
        .icon {
          border-radius: 50%;
          background-color: $input-background-gray;
          font-size: 10px;
          font-weight: 500;
          color: $light-black;
          height: 20px;
          width: 20px;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            background-color: $thick-grey;
            color: $white;
          }
          &:not(:last-child) {
            margin-right: 7px;
          }
        }
        .progress-check-box {
          margin-bottom: 0;
          .ant-checkbox-input {
            cursor: auto;
          }
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            background-color: $thick-green;
            border: none;
            &:after {
              height: 9px;
              width: 5px;
              top: 9px;
            }
          }
          &:not(:last-child) {
            margin-right: 7px;
          }
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 1.1px solid $white;
          border-top: 0;
          border-left: 0;
        }
      }
      .body {
        height: 347px !important;
        background-color: $input-background-gray !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        .content {
          font-size: 44px;
          font-weight: 500;
          color: $black;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        .visibility-hidden {
          visibility: hidden;
        }
        .confirmation-check-box {
          margin-bottom: 0;
          .ant-checkbox-input {
            cursor: auto;
          }
          .ant-checkbox-inner {
            width: 34px;
            height: 34px;
            background-color: $thick-green;
            border: none;
            &:after {
              top: 16px;
            }
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            border: 1.8px solid $white;
            border-top: 0;
            border-left: 0;
          }
        }
      }
    }
  }
}

.completion-modal {
  .ant-modal-content {
    border-radius: 14px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
    .ant-modal-body {
      padding: 29px 50px 32px 50px !important;
      text-align: center;
      .icon {
        background: url(../../images/svgs/sparkWatermark.svg);
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        img {
          height: 32px;
          width: 32px;
        }
      }
      .title {
        color: $background-no-location;
        margin-bottom: 5px;
        font-size: 17px;
        font-weight: 500;
      }
      .info {
        font-size: 13px;
        font-weight: normal;
        color: $black;
        margin-bottom: 10px;
      }
      .ant-btn-primary {
        width: 141px;
        font-weight: 600;
      }
    }
  }
}