@import "variables";
@import "buttons";

html, body, #root, #root > section, #root > div, .ant-layout {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.ant-layout {
  min-height: 100%;
}

.plans-container {
  padding: 45px;

  .ant-col {
    margin-bottom: 20px;
  }

  .plan-card {
    cursor: pointer;
    height: 100%;
    border-radius: 10px;

    &:not(.active):hover {
      background-color: #fafafa;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    }

    .plan-validity {
      color: orange;
      margin: 20px 0;
    }

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }

    &.active {
      border: 2px solid green;
      cursor: unset;
    }
  }
}

.container {
  .ant-layout-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.custom-error-message {
  .ant-message-error {
    position: relative;
    .anticon-close-circle {
      position: absolute !important;
      top: 1px !important;
      left: 1px;
    }
    span:last-child {
      margin-left: 25px !important;
      ul {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.tab-with-icon {
  display: flex;
  align-items: center;

  img {
    height: 18px;
    margin-right: 15px;
  }
}

.ant-card-body {
  .card-title {
    font-weight: 500;
    color: #000;
    margin-bottom: 40px;
  }
}

.ant-modal-body {
  .modal-title {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 40px;
  }
}

.form-modal, .confirmation-modal, .verification-modal, .preview-modal {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-body {
      padding: 50px;
    }
    .title {
      color: $black;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: normal;
      .description {
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
      }
    }
    .content {
      color: $black;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .ant-btn-primary {
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
    }
    .ant-modal-close {
      top: 40px;
      right: 40px;
    }
    .ant-btn[type=submit], .btn-secondary {
      font-weight: bold;
    }
  }

  .align-footer-box {
    margin-bottom: 0;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;

      .btn-grey {
        flex-grow: 2;
      }

      .btn-secondary {
        flex-grow: 1;
      }
    }
  }
}

.form-modal {
  .avatar-thumbnail {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    img {
      border-radius: 10px;
    }
  }
  &.caption-modal{
    .align-footer-box {
      .btn-lg {
        &.btn-danger-outline {
          margin-right: 8px;
          flex-grow: 2;
        }
        &.btn-grey{
          flex-grow: 1;
        }
      }
    }
  }
}

.confirmation-modal {
  text-align: center;
  max-width: 370px;
  .ant-modal-body {
    .modal-logo {
      background: no-repeat;
      height: 100px;
      width: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
    }
    .modal-title{
      font-size: 16px;
      font-weight: 500;
      margin: 20px auto 0;
    }
    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

//footer button box
.text-align-center {
  text-align: center;
  margin-bottom: 0;
}

.align-footer-box {
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    .btn-grey {
      flex-grow: 2;
    }
    .btn-secondary {
      flex-grow: 1;
    }
  }
}

//checkbox
.custom-checkbox {
  margin-bottom: 20px;
  .ant-checkbox-wrapper {
    font-size: 14px;
    color: $black;
    display: flex;
    flex-direction: row;
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox-inner {
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
    .ant-checkbox-inner:after {
      height: 12px;
      width: 7px;
      left: 31%;
    }
  }
}

.custom-checkbox-sm {
  .ant-checkbox-wrapper {
    font-size: 12px;
    color: $black;
    cursor: default;
    .ant-checkbox-input {
      cursor: default;
      &:hover {
        border: none;
      }
    }
    .ant-checkbox-inner:after {
      height: 5px;
      width: 3px;
      left: 25%;
      border: 1px solid $white;
      border-top: 0;
      border-left: 0;
    }
    .ant-checkbox-inner {
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
}

.custom-checkbox-color {
  .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 1px solid $checkbox-background-grey;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $checkbox-background-green;
    border-color: $checkbox-background-green !important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
}

// Initial Loader
.initial-loading {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.tab-layout{
  overflow: auto;
  .no-placeholder {
    [data-rbd-placeholder-context-id] {
      display: none !important;
    }
  }
  &.activities {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .separator + .ant-empty {
      height: 200px;
    }
  }
  &.folders {
    .sparks-only {
      display: flex;
      flex-wrap: wrap;
      .reduce-height {
        height: auto !important;
        .folder-outer {
          height: auto !important;
        }
      }
      .folder-outer {
        width: 288px;
        height: 414px;
        margin: 5px;
      }
    }
    .archived-folder {
      .icons {
        .actions {
          img {
            cursor: default !important;
          }
        }
      }
    }
  }
  &.content {
    height: calc(100vh - 139px);
    background-color: #fafafa;
    border-radius: 5px;
    margin: 5px;
    transition: width 0.5s;
    position: relative;
    &:not(:first-child) {
      margin-left: 0;
    }
  }
  .scroll-activity-container {
    overflow: auto;
    height: 100%;
    padding: 0 15px;
    .separator {
      font-size: 16px;
      font-weight: 500;
      padding-top: 15px;
      color: $black;
    }
  }
  .new-activity {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #eaebec;
    .drop-zone {
      background: $white;
      box-shadow: $card-shadow;
      padding: 15px 0;
      text-align: center;
      border-radius: 4px;
      font-weight: 500;
      color: $primary-color;
      font-size: 16px;
      img {
        margin-right: 2px;
        margin-bottom: 3px;
      }
      &.dragging-over {
        background : #EBEBFF;
        border : 2px solid #4D4EDB;
      }
      div {
        height: 0 !important;
      }
    }
  }
}

.header-tab {
  display: flex;
  background-color: $light-grey;
  width: 100%;
  .header-tab-btn {
    min-width: 60px;
    height: 60px;
    margin: 5px 5px 0 0;
    &:first-child {
      margin: 5px 5px 0;
    }
    div {
      background-color: $white;
      border-radius: 5px;
      padding: 0 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        margin-left: 5px;
        margin-bottom: 1px;
        font-weight: 500;
      }
      &.active-btn {
        border-bottom: 2px solid $primary-color;
        justify-content: flex-start;
        &.with-select{
          div:first-child {
            justify-content: flex-start;
          }
        }
        .home-icon {
          img {
            background-color: $disable-button-background-color;
          }
        }
      }
      &.disabled {
        background-color: #eaebec;
        cursor: not-allowed;
        user-select: none;
        div {
          background-color: #eaebec;
          cursor: not-allowed;
          user-select: none;
        }
      }
    }
    div.count-badge {
      background-color: $danger-color;
      padding: 0px 3px;
      border-radius: 2px;
      line-height: 15px;
      color: $white;
      display: inline-block;
      font-size: 12px;
      height: 16px;
      position: absolute;
      left: 34px;
    }
    &.capture-ui {
      position: relative;
      &.active-capture {
        .count-badge {
          left: 32px;
        }
      }
    }
    .with-select {
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-select-selection-item {
        font-size: 15px;
      }
      .ant-select-arrow {
        right: 22px;
      }
      div:first-child {
        flex-grow: 1;
      }
    }
  }
  img {
    width: 25px;
  }
}
.app-body {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

// Overrides css for autofill background issue
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  background: transparent !important;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.cursor-pointer {
  cursor: pointer !important;
}

//empty
.ant-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes alertShow {
  0% {
    top: -33px;
  }
  15% {
    top: 0;
  }
  80% {
    top: 0;
  }
  100% {
    top: -33px;
  }
}

.parent-banner {
  position: relative;
  .banner-message {
    display: flex;
    width: 100%;
    z-index: 101;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    &.info {
      display: flex;
      //justify-content: space-between;
      .banner-info-icon {
        background-color: $icon-color-no-location;
        padding: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .banner-info-text {
        padding: 8px 0 8px 10px;
        font-size: 14px;
        font-weight: 600;
        color: $black;
        flex-grow: 2;
      }
      .info-close-icon {
        padding: 7px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.alert-off {
      transform: scaleY(0);
    }
    &.display-off {
      display: none;
    }
    &.success {
      background-color: #0ede90;

      .banner-icon {
        background-color: #00c151;
      }
    }

    &.info {
      background-color: #25b8f4;

      .banner-icon {
        background-color: #0584e9;
      }
    }

    &.error {
      background-color: #ff6363;

      .banner-icon {
        background-color: #ff2626;
      }
    }

    .banner-icon {
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 18px;
        width: 18px;
      }
    }

    .banner-text {
      font-size: 14px;
      font-weight: bold;
      padding: 7px 10px 9px 10px;
      color: #000;
    }
  }
}
.ant-upload {
  .ant-avatar {
    cursor: pointer;
  }
}

.avatar-upload-title {
  font-size: 14px;
  font-weight: 500;
  color: $secondary-color;
  text-align: center;
  cursor: pointer;
}
.avatar-loader{
  height: 120px;
  width: 120px;
}
.item-loader-container{
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
}
.ril__outer {
  background: rgba(0, 0, 0, 0.5) !important;
}
.ril__caption, .ril__toolbar {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ril-toolbar__item {
  button {
    outline: none;
  }
}

[id$="ModalMount"] {
  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }
  .confirmation-modal {
    .ant-modal-body {
      text-align: center;
      padding: 20px 15px 15px;
    }
  }
  .confirmation-modal.extra-space {
    .ant-modal-body {
      padding: 30px;
    }
  }
}

.confirmation-modal {
  text-align: center;
  .ant-modal-body > div:not(:last-child){
    margin-bottom: 20px;
  }
  .align-footer-box {
    &.is-delete {
      .ant-form-item-control-input-content {
        button {
          flex-basis: 50%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.form-modal, .verification-modal {
  .ant-modal-close {
    top: 33px !important;
    right: 50px !important;
  }
  .ant-modal-close, .ant-modal-close-x {
    height: 24px;
    width: 24px;
  }
}

.check-box-cus {
  .ant-checkbox + span {
    padding: 0;
  }
  .check-box-element {
    display: flex;
    &.popover {
      img {
        height: 12px;
        width: 12px;
      }
    }
    span:last-child {
      padding: 0 8px;
    }
  }
}


.ant-checkbox-wrapper {
  .ant-checkbox-input {
    + span {
      background-image: url("../images/svgs/check-blank.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:checked + span {
      background-image: url("../images/svgs/check-active.svg");
    }
  }
  .ant-checkbox-checked::after {
    border: none !important;
    content: none !important;
  }
  .ant-checkbox-inner {
    background-color: unset !important;
    border: none !important;
    &::after {
      content: none !important;
    }
  }
}

.no-location {
  background-color: $background-no-location;
  margin: 0 !important;
  width: 600px;
  .ant-col-2 {
    max-width: 5.333333%;
  }
  .no-location-text {
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  .no-location-icon {
    background-color: $icon-color-no-location;
    padding: 7px;
    text-align: center;
  }
  .no-location-close {
    padding: 7px;
    cursor: pointer;
    .no-location-close-btn {
      background: none;
      border: none;
      padding: 0;
      height: 0;
    }
  }
}

#appear-spark-on-top {
  &.folder-outer {
    .folder-header, .folder-body {
      border: 2px solid $secondary-color;
      box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.16);
    }
    .folder-header {
      border-bottom: none;
    }
    .folder-body {
      border-top: none;
    }
  }
}

.folder-outer:not(#appear-spark-on-top) {
  transition: filter 0.3s;
  .full-screen-icon {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}
.display-none {
  display: none;
}
